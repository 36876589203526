
import { wXJsApiPay, rsaKey } from "@/utils/api";
import JSEncrypt from "jsencrypt";
import md5 from "js-md5";
import CryptoJS from "crypto-js";
export const wxPay = {
  data() {
    return {
      orderNo: "",
    };
  },
  methods: {
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" ||
        ua.match(/_SQ_/i) == "_sq_"
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 微信jisapi支付
    wXJsApiPay(result,token) {
      this.orderNo = result.orderNo;
      // 转为json字符串
      const jsonStr = JSON.stringify(result);
      // 创建加密对象实例
      const encryptor = new JSEncrypt();
      rsaKey().then((res) => {
        // 设置RSA公钥
        encryptor.setPrivateKey(res.result);
        // 对内容进行加密
        const rsaStr = encryptor.sign(jsonStr, CryptoJS.SHA256, "sha256");
        // 将加密得到的字符串 rsaStr 用md5摘要
        const md5Str = md5(rsaStr);
        result.head = {
          sign: md5Str,
          token,
          channelId: "h5",
        };
        // return;
        wXJsApiPay(result).then((res) => {
          if (res.code == 200) {
            this.wxPayInvoke(res.result);
          }
        });
      });
    },
    wxPayInvoke(result) {
      if (this.isWeiXin()) {
        var onBridgeReady = () => {
          WeixinJSBridge.invoke("getBrandWCPayRequest", result, function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // alert("支付成功，暂未处理");
              window.location.href = `${window.origin}/paySuccess`;
              // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else {
              // alert("支付失败，暂未处理");
              window.location.href = `${window.origin}/payError`;
            }
          });
        };
        //调用微信支付接口
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              onBridgeReady,
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
          }
        } else {
          onBridgeReady();
        }
      } else {
        this.$toast("请在微信环境下打开，否则无法付款");
      }
    },
  },
};
