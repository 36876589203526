<template>
  <div class="confirm">
    <div class="order">
      <div class="title">
        <p class="icon"></p>
        <p class="text">订单详情</p>
      </div>
      <div class="msg">
        <div class="item">
          <p>产品名称</p>
          <p>{{ productName }}</p>
        </div>
        <div class="item">
          <p>订单号</p>
          <p>{{ $route.query.orderNo }}</p>
        </div>
        <div class="item">
          <p>应付金额</p>
          <p>{{ totalPrem }}元</p>
        </div>
      </div>
    </div>
    <div class="pay">
      <div class="title">
        <p class="icon"></p>
        <p class="text">支付方式</p>
      </div>
      <div class="msg">
        <div class="item">
          <div class="left">
            <img src="../../assets/wx-icon.png" alt="" />
            <p>微信支付</p>
          </div>
          <div class="right">
            <img src="../../assets/check-icon.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="btn" @click="submit">确认支付</div>
  </div>
</template>
<script>
import { getOpenId } from "../../utils/api";
import { wxPay } from "../../mixins/wxPay";
export default {
  mixins: [wxPay],
  data() {
    return {
      productName: sessionStorage.getItem("productName"),
      totalPrem: sessionStorage.getItem("totalPrem"),
      appId: "wx150dd25745296b4e",
    };
  },
  created() {
    if (!this.$route.query.code) {
      this.getWxAuthorize();
    }
  },
  methods: {
    getWxAuthorize() {
      let local = window.location.href;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appId +
        "&redirect_uri=" +
        encodeURIComponent(local) +
        "&response_type=code&scope=snsapi_base&state=1#wechat_redirect";
    },
    //支付
    submit() {
      const params = {
        code: this.$route.query.code,
        channelId: this.appId,
      };
      getOpenId(params).then(({ success, result }) => {
        if (success) {
          const { orderNo } = this.$route.query;
          sessionStorage.setItem("orderNo", orderNo);
          sessionStorage.setItem("openId", result.openId);
          const payChannelId =
            process.env.NODE_ENV === "development"
              ? "1431149436648235010"
              : "1516377621238124545";
          const productId = sessionStorage.getItem("productId");
          this.wXJsApiPay(
            {
              amount: this.totalPrem, //	支付金额,单位为元
              orderNo, //订单号
              productId, //产品Id
              productType: 2, //产品类型
              callBackUrl: `${window.location.origin}/paySuccess`,
              param: `?orderNo=${orderNo}&recordId=${sessionStorage.getItem(
                "recordId"
              )}&productName=${this.productName}`,
              openId: result.openId,
              payChannelId, // 暂时写死
              tradeType: "JSAPI",
            },
            result.accessToken
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.confirm {
  .order {
    margin-top: 16px;
    .title {
      display: flex;
      align-items: center;
      .icon {
        width: 4px;
        height: 14px;
        background: $primary-color;
        border-radius: 0px 2px 2px 0px;
        margin-right: 6px;
      }
      .text {
        font-size: 17px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
    }
    .msg {
      padding: 0 16px;
      .item {
        margin-top: 18px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        p {
          font-size: 15px;
          font-weight: 400;
          color: #666666;
          line-height: 21px;
        }
        p:last-child {
          width: 200px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .pay {
    margin-top: 42px;
    .title {
      display: flex;
      align-items: center;
      .icon {
        width: 4px;
        height: 14px;
        background: $primary-color;
        border-radius: 0px 2px 2px 0px;
        margin-right: 6px;
      }
      .text {
        font-size: 17px;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
      }
    }
    .msg {
      padding: 0 20px;
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 21px;
        .left {
          display: flex;
          align-items: center;
          img {
            width: 24px;
            margin-right: 11px;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }
        }
        .right {
          img {
            width: 20px;
          }
        }
      }
    }
  }
  .btn {
    width: 250px;
    height: 45px;
    margin: 0 auto;
    background: $primary-color;
    border-radius: 23px;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
  }
}
</style>
